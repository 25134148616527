<template>
  <div id="market-win-lose" class="container-fluid mb-5 pb-5">
    <div class="page-header mb-1">
      <strong>สรุปแพ้-ชนะ</strong>
    </div>

    <DateFilter :with-market-id="true" :defaultMarketIds="filters.marketIds" @submit="search" />

    <div v-if="dateText.start===dateText.end">ข้อมูลวันที่ <span class="text-primary">{{dateText.start}}</span></div>
    <div v-else>
      ข้อมูลรวม <span class="text-primary">{{totalDay}}</span> วัน
      ตั้งแต่วันที่ <span class="text-primary">{{dateText.start}}</span>
      <span> ถึง
        <span class="text-primary">{{dateText.end}}</span>
      </span>
    </div>

    <ol v-if="data" id="market-summary" class="breadcrumb px-2 py-1 m-0 mb-2 border-info">
      <li class="breadcrumb-item" v-for="breadcrumb in Breadcrumbs" :class="[{'active': breadcrumb.accountId===parentAccountId}]">
        <span v-if="breadcrumb.accountId===parentAccountId">{{ breadcrumb.username }}</span>
        <a v-else href="#market-summary" @click="toggleChildAccount(breadcrumb.accountId)">{{ breadcrumb.username }}</a>
      </li>
    </ol>

    <div v-if="role==='Member'">
      <MemberTickets
        :filters="filters"
        @Breadcrumbs="setBreadcrumbs"
      />
    </div>
    <div v-else>
      <div class="card">
        <table class="table table-bordered table-pp mb-0">
          <thead>
            <tr>
              <th rowspan="2" colspan="2">ชนิดหวย</th>
              <th width="8%" rowspan="2" style="border-right-width: 2px;">ยอดทั้งหมด</th>
              <th colspan="4" style="border-right-width: 2px;">สมาชิก</th>
              <th colspan="4" style="border-right-width: 2px;">{{agentName}}</th>
              <th colspan="4">บริษัท</th>
            </tr>
            <tr>
              <th width="6%" class="p-1">ส่งออก</th>
              <th width="6%" class="p-1">คอมฯ</th>
              <th width="6%" class="p-1">ถูกรางวัล</th>
              <th width="6%" class="p-1" style="border-right-width: 2px;">รวม</th>
              <th width="6%" class="p-1">ถือหุ้น</th>
              <th width="6%" class="p-1">คอมฯ</th>
              <th width="6%" class="p-1">ถูกรางวัล</th>
              <th width="6%" class="p-1" style="border-right-width: 2px;">รวม</th>
              <th width="6%" class="p-1">ถือหุ้น</th>
              <th width="6%" class="p-1">คอมฯ</th>
              <th width="6%" class="p-1">ถูกรางวัล</th>
              <th width="6%" class="p-1">รวม</th>
            </tr>
          </thead>
          <tbody v-for="item in reportMarkets" :key="item.market._id">
            <tr>
              <td width="60" class="p-0 py-1 text-center" style="cursor: pointer;" @click="showAccount(item)">
                <img :src="item.icon" height="32" class="mx-auto d-block">
              </td>
              <td class="p-0 px-1 text-primary" style="cursor: pointer;" @click="showAccount(item)">{{item.group.groupTitle}} ✦ {{item.market.marketTitle}}</td>
              <td
                class="text-right px-1 text-success"
                style="border-right-width: 2px;">{{ item.summary.amount | amountFormat(2, '0.00') }}</td>

              <td
                class="text-right px-1"
                :class="[{
                  'text-success': item.summary.memberAmount>0
                }, {
                  'text-danger': item.summary.memberAmount<0
                }]"
              >{{ item.summary.memberAmount | amountFormat(2, '0.00') }}</td>
              <td
                class="text-right px-1"
                :class="[{
                  'text-success': item.summary.memberCommission>0
                }, {
                  'text-danger': item.summary.memberCommission<0
                }]"
              >{{ item.summary.memberCommission | amountFormat(2, '0.00') }}</td>
              <td
                class="text-right px-1"
                :class="[{
                  'text-success': item.summary.memberWon>0
                }, {
                  'text-danger': item.summary.memberWon<0
                }]"
              >{{ item.summary.memberWon | amountFormat(2, '0.00') }}</td>
              <td
                class="text-right px-1"
                :class="[{
                  'text-success': item.summary.memberTotal>0
                }, {
                  'text-danger': item.summary.memberTotal<0
                }]"
                style="border-right-width: 2px;"
              >{{ item.summary.memberTotal | amountFormat(2, '0.00') }}</td>

              <td class="text-right px-1"
                :class="[{
                  'text-success': item.summary.agentAmount>0
                }, {
                  'text-danger': item.summary.agentAmount<0
                }]">{{ item.summary.agentAmount | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1"
                :class="[{
                  'text-success': item.summary.agentCommission>0
                }, {
                  'text-danger': item.summary.agentCommission<0
                }]">{{ item.summary.agentCommission | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1"
                :class="[{
                  'text-success': item.summary.agentWon>0
                }, {
                  'text-danger': item.summary.agentWon<0
                }]">{{ item.summary.agentWon | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1" style="border-right-width: 2px;"
                :class="[{
                  'text-success': item.summary.agentTotal>0
                }, {
                  'text-danger': item.summary.agentTotal<0
                }]">{{ item.summary.agentTotal | amountFormat(2, '0.00') }}</td>

              <td class="text-right px-1"
                :class="[{
                  'text-success': item.summary.companyAmount>0
                }, {
                  'text-danger': item.summary.companyAmount<0
                }]">{{ item.summary.companyAmount | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1"
                :class="[{
                  'text-success': item.summary.companyCommission>0
                }, {
                  'text-danger': item.summary.companyCommission<0
                }]">{{ item.summary.companyCommission | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1"
                :class="[{
                  'text-success': item.summary.companyWon>0
                }, {
                  'text-danger': item.summary.companyWon<0
                }]">{{ item.summary.companyWon | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1"
                :class="[{
                  'text-success': item.summary.companyTotal>0
                }, {
                  'text-danger': item.summary.companyTotal<0
                }]">{{ item.summary.companyTotal | amountFormat(2, '0.00') }}</td>
            </tr>
            <tr>
              <td colspan="15" class="p-0">
                <b-collapse :id="'collapse-'+item.market._id" :visible="showAccountMarketId===item.market._id">
                  <WinLoseByAccount
                    :data="data"
                    :marketId="item.market._id"
                    :showName="showName"
                    @toggleAccount="toggleChildAccount"
                    @toggleMember="toggleMemberAccount"
                    class="p-2" />
                </b-collapse>
              </td>
            </tr>
          </tbody>
          <tbody v-if="isLoading && !reportMarkets.length">
            <tr>
              <td colspan="15" class="text-center">กรุณารอซักครู่</td>
            </tr>
          </tbody>
          <tbody v-if="!reportMarkets.length && !isLoading">
            <tr>
              <td colspan="15" class="text-center">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div id="summary" v-if="reportMarkets.length">
        <strong>รวมทั้งหมด</strong>

        <ol class="breadcrumb px-2 py-1 m-0 mb-2 border-info">
          <li class="breadcrumb-item" v-for="breadcrumb in Breadcrumbs" :class="[{'active': breadcrumb.accountId===parentAccountId}]">
            <span v-if="breadcrumb.accountId===parentAccountId">{{ breadcrumb.username }}</span>
            <a v-else href="#summary" @click="toggleChildAccount(breadcrumb.accountId)">{{ breadcrumb.username }}</a>
          </li>
        </ol>

        <div class="card">
          <table class="market-win-lose-account table table-bordered table-info mb-0">
            <thead>
              <tr>
                <th rowspan="2">ชื่อใช้งาน
                  <b-form-checkbox
                    v-model="showName"
                    :value="true"
                    :unchecked-value="false"
                    class="mt-2 font-weight-normal"
                  >
                    แสดงชื่อ
                  </b-form-checkbox>
                </th>
                <th width="6%" rowspan="2">ระดับ</th>
                <th width="8%" rowspan="2" style="border-right-width: 2px;">ยอดทั้งหมด</th>
                <th colspan="4" style="border-right-width: 2px;">สมาชิก</th>
                <th colspan="4" style="border-right-width: 2px;">{{agentName}}</th>
                <th colspan="4">บริษัท</th>
              </tr>
              <tr>
                <th width="6%" class="p-1">ส่งออก</th>
                <th width="6%" class="p-1">คอมฯ</th>
                <th width="6%" class="p-1">ถูกรางวัล</th>
                <th width="6%" class="p-1" style="border-right-width: 2px;">รวม</th>
                <th width="6%" class="p-1">ถือหุ้น</th>
                <th width="6%" class="p-1">คอมฯ</th>
                <th width="6%" class="p-1">ถูกรางวัล</th>
                <th width="6%" class="p-1" style="border-right-width: 2px;">รวม</th>
                <th width="6%" class="p-1">ถือหุ้น</th>
                <th width="6%" class="p-1">คอมฯ</th>
                <th width="6%" class="p-1">ถูกรางวัล</th>
                <th width="6%" class="p-1">รวม</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in accounts" :key="item.agent.childAccountId">
                <td class="p-0 px-1 text-center">
                  <a v-if="item.agent.role==='Agent'" href="#summary" @click="toggleChildAccount(item.agent.childAccountId)">{{ item.agent.username }}</a>
                  <a v-else href="#member-tickets" @click="toggleMemberAccount(item.agent.memberAccountId, null)">{{ item.agent.username }}</a>
                  <small v-if="showName" class="text-secondary ml-1">({{item.agent.firstName}})</small>
                </td>
                <td class="text-center">
                  <AccountRole :role="item.agent.role" />
                </td>
                <td
                  class="text-right px-1"
                  :class="[{
                    'text-success': item.summary.amount>0
                  }, {
                    'text-danger': item.summary.amount<0
                  }]"
                  style="border-right-width: 2px;"
                >{{ item.summary.amount | amountFormat(2, '0.00') }}</td>

                <td
                  class="text-right px-1"
                  :class="[{
                    'text-success': item.summary.memberAmount>0
                  }, {
                    'text-danger': item.summary.memberAmount<0
                  }]"
                >{{ item.summary.memberAmount | amountFormat(2, '0.00') }}</td>
                <td
                  class="text-right px-1"
                  :class="[{
                    'text-success': item.summary.memberCommission>0
                  }, {
                    'text-danger': item.summary.memberCommission<0
                  }]"
                >{{ item.summary.memberCommission | amountFormat(2, '0.00') }}</td>
                <td
                  class="text-right px-1 text-success"
                >{{ item.summary.memberWon | amountFormat(2, '0.00') }}</td>
                <td
                  class="text-right px-1"
                  :class="[{
                    'text-success': item.summary.memberTotal>0
                  }, {
                    'text-danger': item.summary.memberTotal<0
                  }]"
                  style="border-right-width: 2px;"
                >{{ item.summary.memberTotal | amountFormat(2, '0.00') }}</td>

                <td
                  class="text-right px-1"
                  :class="[{
                    'text-success': item.summary.agentAmount>0
                  }, {
                    'text-danger': item.summary.agentAmount<0
                  }]"
                >{{ item.summary.agentAmount | amountFormat(2, '0.00') }}</td>
                <td
                  class="text-right px-1"
                  :class="[{
                    'text-success': item.summary.agentCommission>0
                  }, {
                    'text-danger': item.summary.agentCommission<0
                  }]"
                >{{ item.summary.agentCommission | amountFormat(2, '0.00') }}</td>
                <td
                  class="text-right px-1"
                  :class="[{
                    'text-success': item.summary.agentWon>0
                  }, {
                    'text-danger': item.summary.agentWon<0
                  }]"
                >{{ item.summary.agentWon | amountFormat(2, '0.00') }}</td>
                <td
                  class="text-right px-1"
                  style="border-right-width: 2px;"
                  :class="[{
                    'text-success': item.summary.agentTotal>0
                  }, {
                    'text-danger': item.summary.agentTotal<0
                  }]"
                >{{ item.summary.agentTotal | amountFormat(2, '0.00') }}</td>

                <td
                  class="text-right px-1"
                  :class="[{
                    'text-success': item.summary.companyAmount>0
                  }, {
                    'text-danger': item.summary.companyAmount<0
                  }]"
                >{{ item.summary.companyAmount | amountFormat(2, '0.00') }}</td>
                <td
                  class="text-right px-1"
                  :class="[{
                    'text-success': item.summary.companyCommission>0
                  }, {
                    'text-danger': item.summary.companyCommission<0
                  }]"
                >{{ item.summary.companyCommission | amountFormat(2, '0.00') }}</td>
                <td
                  class="text-right px-1"
                  :class="[{
                    'text-success': item.summary.companyWon>0
                  }, {
                    'text-danger': item.summary.companyWon<0
                  }]"
                >{{ item.summary.companyWon | amountFormat(2, '0.00') }}</td>
                <td
                  class="text-right px-1"
                  :class="[{
                    'text-success': item.summary.companyTotal>0
                  }, {
                    'text-danger': item.summary.companyTotal<0
                  }]"
                >{{ item.summary.companyTotal | amountFormat(2, '0.00') }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="2" class="text-center">รวม</th>
                <th
                  class="text-right px-1"
                  style="border-right-width: 2px;"
                  :class="[{
                    'text-success': summary.amount>0
                  }, {
                    'text-danger': summary.amount<0
                  }]"
                >{{ summary.amount | amountFormat(2, '0.00') }}</th>
                <th
                  class="text-right px-1"
                  :class="[{
                    'text-success': summary.memberAmount>0
                  }, {
                    'text-danger': summary.memberAmount<0
                  }]"
                >{{ summary.memberAmount | amountFormat(2, '0.00') }}</th>
                <th
                  class="text-right px-1"
                  :class="[{
                    'text-success': summary.memberCommission>0
                  }, {
                    'text-danger': summary.memberCommission<0
                  }]"
                >{{ summary.memberCommission | amountFormat(2, '0.00') }}</th>
                <th
                  class="text-right px-1"
                  :class="[{
                    'text-success': summary.memberWon>0
                  }, {
                    'text-danger': summary.memberWon<0
                  }]"
                >{{ summary.memberWon | amountFormat(2, '0.00') }}</th>
                <th
                  class="text-right px-1"
                  style="border-right-width: 2px;"
                  :class="[{
                    'text-success': summary.memberTotal>0
                  }, {
                    'text-danger': summary.memberTotal<0
                  }]"
                >{{ summary.memberTotal | amountFormat(2, '0.00') }}</th>
                <th class="text-right px-1"
                  :class="[{
                    'text-success': summary.agentAmount>0
                  }, {
                    'text-danger': summary.agentAmount<0
                  }]">{{ summary.agentAmount | amountFormat(2, '0.00') }}</th>
                <th class="text-right px-1"
                  :class="[{
                    'text-success': summary.agentCommission>0
                  }, {
                    'text-danger': summary.agentCommission<0
                  }]">{{ summary.agentCommission | amountFormat(2, '0.00') }}</th>
                <th class="text-right px-1"
                  :class="[{
                    'text-success': summary.agentWon>0
                  }, {
                    'text-danger': summary.agentWon<0
                  }]">{{ summary.agentWon | amountFormat(2, '0.00') }}</th>
                <th class="text-right px-1" style="border-right-width: 2px;"
                  :class="[{
                    'text-success': summary.agentTotal>0
                  }, {
                    'text-danger': summary.agentTotal<0
                  }]">{{ summary.agentTotal | amountFormat(2, '0.00') }}</th>
                <th class="text-right px-1"
                  :class="[{
                    'text-success': summary.companyAmount>0
                  }, {
                    'text-danger': summary.companyAmount<0
                  }]">{{ summary.companyAmount | amountFormat(2, '0.00') }}</th>
                <th class="text-right px-1"
                  :class="[{
                    'text-success': summary.companyCommission>0
                  }, {
                    'text-danger': summary.companyCommission<0
                  }]">{{ summary.companyCommission | amountFormat(2, '0.00') }}</th>
                <th class="text-right px-1"
                  :class="[{
                    'text-success': summary.companyWon>0
                  }, {
                    'text-danger': summary.companyWon<0
                  }]">{{ summary.companyWon | amountFormat(2, '0.00') }}</th>
                <th class="text-right px-1"
                  :class="[{
                    'text-success': summary.companyTotal>0
                  }, {
                    'text-danger': summary.companyTotal<0
                  }]">{{ summary.companyTotal | amountFormat(2, '0.00') }}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EEEEEE"
      :height="80"
      :width="80"
      color="#E5881E"
      loader="dots"
    />
  </div>
</template>
<script>
import DateFilter from './components/DateFilter'
import ReportService from "@/services/ReportService"
import Swal from 'sweetalert2'
import moment from '@/helpers/moment'
import _ from 'lodash'
import WinLoseByAccount from './components/WinLoseByAccount'
import MemberTickets from './components/MemberTickets'
import cAlert from '@/helpers/alert'

export default {
  name: 'MarketWinLose',
  components: {
    DateFilter,
    WinLoseByAccount,
    MemberTickets
  },
  data() {
    return {
      role: 'Agent',
      data: null,
      isLoading: false,
      filters: {
        startDate: null,
        endDate: null,
        accountId: null,
        marketIds: []
      },
      Breadcrumbs: [],
      showName: false,
      showAccountMarketId: null,
      viewMemberMarketId: null
    }
  },
  computed: {
    currentAccountId() {
      return this.$store.state.account._id
    },
    parentAccountId() {
      if(this.Breadcrumbs.length === 0) {
        return this.currentAccountId
      }else{
        return this.Breadcrumbs[this.Breadcrumbs.length - 1].accountId
      }
    },
    agentName() {
      if(!this.data)
        return ''

      return this.data?.agent?.username
    },
    markets() {
      return this.$store.state.markets
    },
    marketGroup() {
      return this.$store.state.marketGroups
    },
    dateText() {
      return {
        start: moment(this.filters.startDate).add(543, 'y').format('D MMMM YYYY'),
        end: moment(this.filters.endDate).add(543, 'y').format('D MMMM YYYY')
      }
    },
    totalDay() {
      return moment(this.filters.endDate).diff(this.filters.startDate, 'd') + 1
    },
    reportMarkets() {
      if(!this.data)
        return []

      const groups = _.groupBy(this.data.reports, (report)=>{
        return report.market.marketId
      })

      const markets = []
      for(const marketId in groups) {

        const summary = groups[marketId].reduce((total, item)=>{
          total.amount += item.amount

          total.memberAmount += item.memberAmount
          total.memberCommission += item.memberCommission
          total.memberWon += item.memberWon
          total.memberTotal += (item.memberAmount+item.memberCommission+item.memberWon)

          total.agentAmount += item.agentAmount
          total.agentCommission += item.agentCommission
          total.agentWon += item.agentWon
          total.agentTotal += (item.agentAmount+item.agentCommission+item.agentWon)

          total.companyAmount += item.companyAmount
          total.companyCommission += item.companyCommission
          total.companyWon += item.companyWon
          total.companyTotal += (item.companyAmount+item.companyCommission+item.companyWon)

          return total
        }, {
          amount: 0,

          memberAmount: 0,
          memberCommission: 0,
          memberWon: 0,
          memberTotal: 0,

          agentAmount: 0,
          agentCommission: 0,
          agentWon: 0,
          agentTotal: 0,

          companyAmount: 0,
          companyCommission: 0,
          companyWon: 0,
          companyTotal: 0
        })

        const market = this.markets.find((market)=>{
          return market._id === groups[marketId][0].market?.marketId
        })

        const marketGroup = this.marketGroup.find((g)=>{
          return g._id === groups[marketId][0].market?.groupId
        })

        markets.push({
          market: market,
          group: marketGroup,
          summary: summary,
          icon: market?.imageIcon
        })
      }

      return _.orderBy(markets, ['group.sort', 'market.sort', 'market.marketTitle'], ['asc', 'asc', 'asc'])
    },
    accounts() {
      if(!this.data)
        return []

      const reports = this.data.reports.map((report)=>{

        const child = this.data.childs.find((child)=>{
          return child.memberAccountId === report._id.accountId
        })

        const account = this.data.accounts.find((account)=>{
          return account.childAccountId === child.childAccountId
        })

        return {
          ...report,
          agent: account
        }
      })

      const groups = _.groupBy(reports, (report)=>{
        return report.agent.childAccountId
      })

      const accounts = []
      for(const accountId in groups) {

        const summary = groups[accountId].reduce((total, item)=>{
          total.amount += item.amount

          total.memberAmount += item.memberAmount
          total.memberCommission += item.memberCommission
          total.memberWon += item.memberWon
          total.memberTotal += (item.memberAmount+item.memberCommission+item.memberWon)

          total.agentAmount += item.agentAmount
          total.agentCommission += item.agentCommission
          total.agentWon += item.agentWon
          total.agentTotal += (item.agentAmount+item.agentCommission+item.agentWon)

          total.companyAmount += item.companyAmount
          total.companyCommission += item.companyCommission
          total.companyWon += item.companyWon
          total.companyTotal += (item.companyAmount+item.companyCommission+item.companyWon)

          return total
        }, {
          amount: 0,

          memberAmount: 0,
          memberCommission: 0,
          memberWon: 0,
          memberTotal: 0,

          agentAmount: 0,
          agentCommission: 0,
          agentWon: 0,
          agentTotal: 0,

          companyAmount: 0,
          companyCommission: 0,
          companyWon: 0,
          companyTotal: 0
        })

        accounts.push({
          agent: groups[accountId][0].agent,
          summary: summary
        })
      }

      return _.orderBy(accounts, ['agent.username'], ['asc'])
    },
    summary() {
      return this.accounts.reduce((total, item)=>{
        total.amount += item.summary.amount
        total.memberAmount += item.summary.memberAmount
        total.memberCommission += item.summary.memberCommission
        total.memberWon += item.summary.memberWon
        total.memberTotal += item.summary.memberTotal
        total.agentAmount += item.summary.agentAmount
        total.agentCommission += item.summary.agentCommission
        total.agentWon += item.summary.agentWon
        total.agentTotal += item.summary.agentTotal
        total.companyAmount += item.summary.companyAmount
        total.companyCommission += item.summary.companyCommission
        total.companyWon += item.summary.companyWon
        total.companyTotal += item.summary.companyTotal
        return total
      }, {
        amount: 0,
        memberAmount: 0,
        memberCommission: 0,
        memberWon: 0,
        memberTotal: 0,
        agentAmount: 0,
        agentCommission: 0,
        agentWon: 0,
        agentTotal: 0,
        companyAmount: 0,
        companyCommission: 0,
        companyWon: 0,
        companyTotal: 0
      })
    }
  },
  methods: {
    search(filters) {
      this.filters = {
        ...this.filters,
        startDate: filters.date.start,
        endDate: filters.date.end,
        marketIds: filters.marketIds
      }

      if(filters.marketIds.length > 0 && this.role==='Agent')
        this.getReports()
    },
    getReports() {
      this.role = 'Agent'
      this.isLoading = true
      ReportService.getMarketWinLose(this.filters).then((response)=>{
        if(response.success) {
          this.data = response.data
          this.Breadcrumbs = response.data.agent.parents
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดรายงานไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    toggleChildAccount(accountId) {
      this.filters.accountId = accountId
      this.getReports()
    },
    toggleMemberAccount(accountId, marketId) {
      this.role = 'Member'
      this.filters.accountId = accountId

      if(marketId)
        this.filters.marketIds = [marketId]
    },
    showAccount(item) {
      if(this.showAccountMarketId !== item.market._id)
        this.showAccountMarketId = item.market._id
      else
        this.showAccountMarketId = null
    },
    setBreadcrumbs(breadcrumbs) {
      this.Breadcrumbs = breadcrumbs
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
        font-weight: normal;
        font-size: 85%;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td, th {
        padding: 5px;
        vertical-align: middle;
        font-size: 85%;
      }
    }
  }
  tfoot {
    tr {
      td, th {
        font-size: 85%;
      }
    }
  }
}
</style>
<style lang="scss">
.market-win-lose-account {
  tbody {
    tr {
      td {
        .badge {
          font-size: 85%;
          font-weight: normal;
        }
      }
    }
  }
}
</style>
