<template>
  <div>
    <table class="table table-bordered table-info">
      <thead>
        <tr>
          <th width="60">ลำดับ</th>
          <th class="pb-1">ชื่อใช้งาน
            <b-form-checkbox
              v-model="showName"
              :value="true"
              :unchecked-value="false"
              class="mt-2 font-weight-normal"
            >
              แสดงชื่อ
            </b-form-checkbox>
          </th>
          <th>งวด</th>
          <th>อัตราจ่าย</th>
          <th>เวลา</th>
          <th width="8%">รายการ</th>
          <th width="8%">ยอดบิล</th>
          <th width="8%">ส่วนลด</th>
          <th width="8%">สุทธิ</th>
          <th width="8%">ถูกรางวัล</th>
          <th width="8%">ได้เสีย</th>
          <th>สถานะ</th>
          <th>หมายเหตุ</th>
          <th width="60"></th>
        </tr>
      </thead>
      <tbody v-if="!isLoading" v-for="group in groups" :key="group.market._id">
        <tr>
          <th colspan="5" class="alert-warning">{{group.market.groupTitle}} ✦ {{group.market.marketTitle}}</th>
          <th class="text-center alert-warning">{{group.summary.length}}</th>
          <th class="text-right alert-warning text-success">{{group.summary.amount | amountFormat}}</th>
          <th class="text-right alert-warning text-danger">-{{group.summary.discount | amountFormat}}</th>
          <th class="text-right alert-warning text-success">{{group.summary.credit | amountFormat}}</th>
          <th class="text-right alert-warning text-success">{{group.summary.won | amountFormat}}</th>
          <th
            class="text-right alert-warning"
            :class="[{
              'text-success': group.summary.winlose>0
            }, {
              'text-danger': group.summary.winlose<0
            }]"
          >{{group.summary.winlose | amountFormat}}</th>
          <th colspan="3" class="alert-warning"></th>
        </tr>
        <tr v-for="(ticket, index) in group.tickets" :key="ticket._id">
          <td class="text-center">{{index+1}}</td>
          <td class="text-center">
            {{ ticket.account.mainUser.username }}
            <small v-if="showName" class="text-secondary">({{ticket.account.mainUser.firstName}})</small>
          </td>
          <td class="text-center">{{ticket.round.roundDate}}</td>
          <td class="text-center">{{ticket.rate.rateTitle}}</td>
          <td class="text-center">
            {{ticket.createdAt | dispDateTime("DD/MM/YYYY HH:mm:ss")}}
          </td>
          <td class="text-center">{{ticket.summary.length}}</td>
          <td class="text-right text-success">{{ticket.summary.amount | amountFormat}}</td>
          <td class="text-right text-danger">-{{ticket.summary.discount | amountFormat}}</td>
          <td class="text-right text-success">{{ticket.summary.credit | amountFormat}}</td>
          <td class="text-right text-success">{{ticket.summary.won | amountFormat}}</td>
          <td
            class="text-right"
            :class="[{
              'text-success': ticket.summary.winlose>0
            }, {
              'text-danger': ticket.summary.winlose<0
            }]"
          >{{ticket.summary.winlose | amountFormat}}</td>
          <td class="text-center">
            <TicketStatus :status="ticket.status" />
          </td>
          <td class="text-center">{{ticket.remarks}}</td>
          <td class="text-center">
            <button class="btn btn-outline-info btn-sm" @click="viewTicket(ticket._id)"><i class="fas fa-list"></i></button>
          </td>
        </tr>
      </tbody>
      <tbody v-if="!isLoading && !groups.length">
        <tr>
          <td colspan="14" class="text-center">ไม่มีข้อมูล</td>
        </tr>
      </tbody>
      <tbody v-if="isLoading">
        <tr>
          <td colspan="14" class="text-center">กรุณารอซักครู่</td>
        </tr>
      </tbody>
    </table>
    <ViewTicketModal :is-show="isShowTicketModal" :ticket-id="viewTicketId" @close="isShowTicketModal=false" />

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EEEEEE"
      :height="30"
      :width="30"
      color="#E5881E"
      loader="dots"
    />
  </div>
</template>
<script>
import ReportService from "@/services/ReportService"
import Swal from 'sweetalert2'
import moment from '@/helpers/moment'
import _ from 'lodash'
import ViewTicketModal from '@/views/setting-report/components/ViewTicketModal'
import cAlert from '@/helpers/alert'

export default {
  name: 'MemberTickets',
  components: {
    ViewTicketModal
  },
  props: ['filters'],
  data() {
    return {
      isLoading: false,
      data: null,
      showName: false,
      isShowTicketModal: false,
      viewTicketId: null
    }
  },
  computed: {
    changed() {
      return this.filters
    },
    markets() {
      return this.$store.state.markets
    },
    groups() {
      if(!this.data)
        return []

      const items = this.data.tickets.map((ticket)=>{
        ticket.summary.winlose = (-ticket.summary.credit+ticket.summary.won)
        return ticket
      })

      const groups = _.groupBy(this.data.tickets, (ticket)=>{
        return ticket.market.marketId
      })

      const markets = []
      for(const marketId in groups) {
        const summary = groups[marketId].reduce((total, item)=>{
          total.amount += item.summary.amount
          total.credit += item.summary.credit
          total.discount += item.summary.discount
          total.won += item.summary.won
          total.winlose += item.summary.winlose
          total.length += item.summary.length
          return total
        }, {
          amount: 0,
          credit: 0,
          discount: 0,
          won: 0,
          winlose: 0,
          length: 0
        })

        const market = this.markets.find((m)=>{
          return m._id === marketId
        })

        markets.push({
          market: {
            ...market,
            ...groups[marketId][0].market
          },
          tickets: groups[marketId],
          summary: summary
        })
      }

      return _.orderBy(markets, ['round.close'], ['asc'])
    }
  },
  watch: {
    changed() {
      this.getReport()
    }
  },
  methods: {
    getReport() {

      if(!this.filters.marketIds.length)
        return

      this.isLoading = true
      ReportService.getMemberWinLoseTickets(this.filters)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
          this.$emit('Breadcrumbs', response.data.member.parents)
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    viewTicket(ticketId) {
      this.isShowTicketModal = true
      this.viewTicketId = ticketId
    }
  },
  mounted() {
    this.getReport()
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
        font-weight: 500;
        line-height: 1;
        font-size: 85%;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        padding: 5px;
        vertical-align: middle;
        font-size: 85%;
      }
      th {
        padding: 5px;
        font-size: 85%;
      }
    }
  }
  tfoot {
    tr {
      td {
        font-size: 85%;
      }
    }
  }
}
</style>
<style lang="scss">
.table-wait-account {
  tbody {
    tr {
      td {
        .badge {
          font-size: 90%;
          font-weight: normal;
        }
      }
    }
  }
}
</style>
